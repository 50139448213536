var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.data.block, { "mb-2": _vm.layout !== "template7" }],
      attrs: { id: "banner-type1" }
    },
    [
      _c(
        "swiper",
        {
          ref: "slider",
          staticClass: "swiper",
          attrs: { options: _vm.swiperOption }
        },
        [
          _vm._l(_vm.banners, function(banner) {
            return _c(
              "swiper-slide",
              { key: banner._id },
              [
                _c("v-img", {
                  attrs: {
                    contain: "",
                    src: _vm.baseImgUrl + banner.mobile_image || _vm.image
                  },
                  on: {
                    click: function($event) {
                      return _vm.clickBanner(
                        banner.mobile_hyperlink,
                        _vm.$router
                      )
                    }
                  }
                })
              ],
              1
            )
          }),
          _c("div", {
            staticClass: "swiper-pagination",
            style: _vm.customPaginationColor,
            attrs: { slot: "pagination" },
            slot: "pagination"
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }